import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import '@reach/dialog/styles.css'
import { Dialog, DialogOverlay, DialogContent } from '@reach/dialog'

import { Transactions } from './transactions'
import { StatsModal } from '../stats_modal'
import Loading from '../loading'

import CloseIcon from '../../assets/close.svg'
import TransactionArrowIconImage from '../../assets/transaction-arrow.svg'

export const ProofModal = props => {
    const { onClose, proof, proofId, isOpen } = props
    const [transactions, setTransactions] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(true)
        if (proofId) {
            fetch(`https://starkgateway.0x.org/v1/batch/${proofId}`, {
                method: 'GET',
            })
                .then(response => {
                    return response.json()
                })
                .then(json => {
                    setTransactions(json)
                    setIsLoading(false)
                })
                .catch(console.log)
        }
    }, [proofId])

    return (
        <Overlay onDismiss={() => onClose()} isOpen={isOpen}>
            <Content>
                <Container>
                    <Column>
                        <Heading>Proof #{proof.id}</Heading>
                        <MoreLink
                            href={`https://ropsten.etherscan.io/tx/${
                                proof.txHash
                            }`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            View on Etherscan <TransactionArrowIcon />
                        </MoreLink>

                        <StatsModal stats={proof} />
                    </Column>
                    <Column>
                        <TableHeading>Trades</TableHeading>
                        <TableContainer isLoading={isLoading}>
                            <Transactions
                                isLoading={isLoading}
                                transactions={transactions}
                            />
                            {isLoading && <Loading />}
                        </TableContainer>
                    </Column>
                </Container>
                <Close onClick={props.onClose}>
                    <CloseIcon />
                </Close>
            </Content>
        </Overlay>
    )
}

ProofModal.defaultProps = {
    proof: {
        id: null,
        url: null,
    },
}

/**
 * Modal
 */
const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (min-width: 768px) {
        display: grid;
        grid-template-columns: 1fr 3fr;
        height: 100%;
    }

    > div {
        position: relative;

        &:nth-child(2) {
            flex-grow: 1;
        }
    }
`

const Overlay = styled(DialogOverlay)`
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    z-index: 999;
`

const Content = styled(DialogContent)`
    background-color: #fff;
    border-radius: 20px;
    margin: auto !important;
    font-size: 1rem;
    padding: 0 40px 0;
    position: relative;
    padding: 20px 15px 20px !important;
    width: 1150px !important;
    max-width: calc(100%);
    height: 80vh;

    @media (min-width: 768px) {
        width: 1000px !important;
        max-width: calc(100% - 40px);
        padding: 0 40px 0 !important;
        height: auto;
        min-height: 560px;
        max-height: 560px;
    }
`

const Column = styled.div`
    @media (min-width: 768px) {
        height: 560px;
        padding: 40px 0 40px;

        &:first-of-type {
            border-right: 1px solid rgba(44, 103, 116, 0.6);
            padding-right: 40px;
        }
        &:last-of-type {
            padding-left: 40px;
        }
    }
`

const TableContainer = styled.div`
    height: 100%;
    max-height: 100%;
    overflow-y: ${props => (props.isLoading ? 'hidden' : 'auto')};
    position: relative;
    //max-height: 328px;
    max-height: 44vh;

    @media (min-width: 768px) {
        height: 560px;
        max-height: calc(100% - 20px);
    }
`

const Heading = styled.h2`
    font-size: 1.5rem;
    margin-bottom: 5px;
    font-weight: 700;

    @media (min-width: 768px) {
        font-size: 2.5rem;
        margin-bottom: 19px;
    }
`

const TableHeading = styled.h3`
    font-size: 1.125rem;
    margin-bottom: 8px;
    font-weight: 500;
    display: none;

    @media (min-width: 768px) {
        display: block;
    }
`

const Close = styled.button`
    padding: 0;
    border: 0;
    position: absolute;
    right: 20px;
    top: 20px;
    opacity: 0.5;
`

const Test = styled.div`
    background-color: yellow;
    height: 700px;
`

const MoreLink = styled.a`
    font-size: inherit;
    display: inline-block;
    font-weight: 500;
    margin-bottom: 20px;

    @media (min-width: 768px) {
        margin-bottom: 50px;
    }
`

const TransactionArrowIcon = styled(TransactionArrowIconImage)`
    margin-left: 7px;

    @media (max-width: 768px) {
        margin-left: auto;
        width: 10px;
        height: 10px;
    }
`
