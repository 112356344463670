import React from 'react'
import styled from 'styled-components'

import ArrowImage from '../../assets/faq_arrow.svg'

export class Question extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { currentOpenIndex, id, question, answer } = this.props

        return (
            <Container
                onClick={this._onClick.bind(this)}
                onKeyPress={this._onKeyPress.bind(this)}
                open={id === currentOpenIndex}
            >
                <Summary>
                    <Arrow/>
                    {question
                }</Summary>
                <Answer>{answer}</Answer>
            </Container>
        )
    }
    _onClick(e) {
        const { id, onToggle } = this.props
        if (e.target.nodeName !== 'SUMMARY') {
            return false;
        }
        e.preventDefault()

        onToggle(id)
    }
    _onKeyPress(e) {
        const { id, onToggle } = this.props
        e.preventDefault()

        if (e.key === 'Enter') {
            onToggle(id)
        }
    }
}

Question.defaultProps = {
    onToggle: () => {},
}

const Container = styled.details`
    border-bottom: 1px solid rgba(44, 103, 116, 0.6);
    text-align: left;
    max-width: 730px;
    margin-left: auto;
    margin-right: auto;

    &:first-of-type {
        border-top: 1px solid rgba(44, 103, 116, 0.6);
    }
`

const Summary = styled.summary`
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.4;
    padding: 10px 0;
    outline: 0;
    position: relative;
    list-style: none;
    padding-left: 22px;
    
    &::-webkit-details-marker {
        display: none;
    }

    &:focus {
        opacity: 0.7;
    }

    @media (min-width: 768px) {
        font-size: 1.125rem;
        padding: 10px 20px 10px 50px;
    }
`

const Arrow = styled(ArrowImage)`
    position: absolute;
    left: 0px;
    top: 15px;

    @media (min-width: 768px) {
        left: 20px;
        top: 17px;
    }

    details[open] & {
        transform: rotate(90deg);
    }
`

const Answer = styled.div`
    font-size: 0.875rem;
    padding: 5px 0 5px;

    @media (min-width: 768px) {
        padding: 10px 20px 5px;
    }

    a {
        text-decoration: underline;
        transition: opacity .25s ease-in-out;

        &:hover {
            opacity: 0.7;
        }
    }

    p {
        margin-bottom: 1rem;
        line-height: 1.5;

        @media (min-width: 768px) {
            line-height: 1.4;
        }
    }

    ol {
        margin-bottom: 1rem;
        list-style: disc;
        padding-left: 1rem;

        li {
            line-height: 1.5;
        }

        li + li {
            margin-top: 5px;
        }
    }
`
