import React from 'react'
import styled from 'styled-components'
import { Question } from './question'

export const Category = ({ id, title, questions, onToggle, currentOpenIndex }) => (
    <Container>
        <Heading>{title}</Heading>
        {questions.map((question, index) => (
            <Question
                key={`${title}-question-${index}`}
                question={question.question}
                answer={question.answer}
                id={`${id}-question-${index}`}
                currentOpenIndex={currentOpenIndex}
                onToggle={onToggle}
            />
        ))}
    </Container>
)

Category.defaultProps = {
    onToggle: () => {},
}
/*
    Category
*/
const Container = styled.div`
    margin-bottom: 60px;
`

/*
    Question
*/
const Heading = styled.h3`
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 15px;

    @media (min-width: 768px) {
        font-size: 1.75rem;
        margin-bottom: 25px;
    }
`