import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Loading from '../loading'
import BetweenIconImage from '../../assets/transaction-separator-icon.svg'
import TransactionArrowIconImage from '../../assets/transaction-arrow.svg'

import {
    TweenMax,
    TimelineMax,
    ModifiersPlugin,
    RoundPropsPlugin,
    AttrPlugin,
    CSSPlugin,
    Power4,
    Linear,
} from 'gsap/all'
import Transition from 'react-transition-group/Transition'
import TransitionGroup from 'react-transition-group/TransitionGroup'

const ANIMATION_DONE_EVENT = 'animation::done'
const triggerAnimationDoneEvent = node => {
    node.dispatchEvent(new Event(ANIMATION_DONE_EVENT))
}

const decimalFormatter = (number, maximumFractionDigits = 0) => {
    return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits,
    }).format(number)
}

const animateCardIn = card => {
    TweenMax.fromTo(
        card,
        1.5,
        {
            y: '-30px',
            ease: Linear.easeInOut,
            opacity: 0,
        },
        {
            y: 0,
            opacity: 1,
            onComplete: () => triggerAnimationDoneEvent(card),
        }
    )
}

const animateCardOut = card =>
    TweenMax.fromTo(
        card,
        1,
        {
            y: '-=50%',
            ease: Linear.easeInOut,
            opacity: 1,
        },
        {
            y: '-=50%',
            opacity: 0,
            onComplete: () => triggerAnimationDoneEvent(card),
        }
    )

const addEndListener = (node, done) =>
    node.addEventListener(ANIMATION_DONE_EVENT, done)

export class ProofTable extends React.Component {
    constructor(props) {
        super(props)

        this.tableRef = React.createRef()
        this._onClick = this._onClick.bind(this)
        this.onAnimateIn = this.onAnimateIn.bind(this)
        this.cardRefs = {}
    }

    componentDidMount() {}

    onAnimateIn(card, transaction) {
        animateCardIn(card)
    }

    render() {
        const { proofs, isLoading } = this.props

        return (
            <Container>
                {/* <Heading>Live Transactions</Heading>*/}
                <Heading size="small">Latest Proofs</Heading>
                <ListWrapper>
                    <List isLoading={isLoading}>
                        <ListInner ref={ref => (this.tableRef = ref)}>
                            <TransitionGroup component={null}>
                                {proofs.map((transaction, index) => (
                                    <Transition
                                        key={`proof-${transaction.id}`}
                                        onEnter={card =>
                                            this.onAnimateIn(card, transaction)
                                        }
                                        onExit={animateCardOut}
                                        addEndListener={addEndListener}
                                    >
                                        <Transaction
                                            ref={ref =>
                                                (this.cardRefs[
                                                    'ref-' + transaction.id
                                                ] = ref)
                                            }
                                            onClick={event =>
                                                this._onClick(
                                                    event,
                                                    transaction
                                                )
                                            }
                                            {...transaction}
                                        />
                                    </Transition>
                                ))}
                            </TransitionGroup>
                        </ListInner>
                    </List>
                    {isLoading && <Loading />}
                </ListWrapper>
            </Container>
        )
    }

    _onClick(e, transaction) {
        // eslint-disable-next-line no-debugger
        e.preventDefault()

        this.props.onClick(transaction)
    }
}

ProofTable.defaultProps = {
    isLoading: true,
    proofs: [],
    onClick: () => {},
}

const Transaction = React.forwardRef(
    ({ id, gas, txCount, txHash, onClick }, ref) => {
        const url = `https://etherscan.io/tx/${txHash}`
        const gasPriceConverted = decimalFormatter(gas)

        return (
            <TransactionContainer href={url} onClick={onClick} ref={ref}>
                <IdDetail>Proof #{id}</IdDetail>
                <HashDetail>
                    <strong>{txCount}</strong> <span>trades</span>
                </HashDetail>
                <TotalGasDetail>
                    <strong>{gasPriceConverted}</strong> total gas
                </TotalGasDetail>
                <ViewDetail>
                    <ViewDetailText>View Details</ViewDetailText>
                    <TransactionArrowIcon />
                </ViewDetail>
            </TransactionContainer>
        )
    }
)

Transaction.displayName = 'Transaction'

/**
 * Transaction list styles
 */
const Container = styled.div`
    grid-column: 1 / 13;

    @media (min-width: 768px) {
        grid-column: 4 / 10;
        margin-top: -48px;
    }
`
const Heading = styled.h2`
    font-size: 1.25rem;
    margin-bottom: 19px;
    font-weight: 500;
`

const List = styled.div`
    position: relative;
    overflow-y: auto;
    height: 345px;
    max-height: 345px;
    margin-bottom: 0;
    padding: 0 0;
    opacity: ${props => props.isLoading && 0};
`

const ListInner = styled.div`
    position: relative;
`

const ListWrapper = styled.div`
    position: relative;
`

/**
 * Transaction styles
 */
const TransactionContainer = styled.a.attrs({
    target: '_blank',
})`
    min-height: 34px;
    background: #f4fcfa;
    color: #2c6774;
    border-radius: 20px;
    margin-bottom: 5px;
    //display: grid;
    //grid-template-columns: 1.5fr 1.5fr 1fr;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;
    transition: opacity 0.35s ease-in-out;

    &:hover {
        opacity: 0.5;
    }

    &:last-child {
        margin-bottom: 0;
    }

    @media (max-width: 768px) {
        justify-content: space-between;
    }
`

const Detail = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1rem;

    @media (max-width: 768px) {
        flex-shrink: 0;
        font-size: 0.75rem;
    }
`

const IdDetail = styled(Detail)`
    min-width: 95px;

    @media (max-width: 768px) {
        flex-shrink: 0;
        min-width: 70px;
    }
`

const HashDetail = styled(Detail)`
    margin-left: 60px;

    .sm {
        display: none;
    }

    @media (max-width: 768px) {
        margin-left: 0;

        // display: none;

        .lg {
            display: none;
        }

        .sm {
            display: inline-block;
        }
    }
`

const TotalGasDetail = styled(Detail)`
    margin-left: auto;

    @media (max-width: 768px) {
        margin-left: 0;
        // display: none;
    }
`
const ViewDetail = styled(Detail)`
    margin-left: 30px;

    @media (max-width: 768px) {
        margin-left: 0;
        // display: none;
    }
`

const ViewDetailText = styled.span`
    display: inline-block;

    @media (max-width: 768px) {
        display: none;
    }
`

const TransactionArrowIcon = styled(TransactionArrowIconImage)`
    margin-left: 7px;

    @media (max-width: 768px) {
        margin-left: auto;
        width: 10px;
        height: 10px;
    }
`
