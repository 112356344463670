import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled, { keyframes } from 'styled-components'

export const LiveButton = props => (
    <Container>
        <Button>
            <DotContainer>
                <Dot />
                <Pulse />
            </DotContainer>
            <Text>{props.isLive === true ? 'Live on Ropsten' : 'Replay'}</Text>
        </Button>
        <Description>
            {props.isLive === true
                ? 'Sampling from Binance at a rate of 10 trades/sec'
                : ''}
        </Description>
    </Container>
)

const Button = styled.div`
    background: #f4fcfa;
    border-radius: 30px;
    padding: 5px 8px;
    display: inline-flex;
    align-items: center;
    margin-bottom: 10px;

    @media (min-width: 768px) {
        border-radius: 12px;
        padding: 15px 15px;
        margin-bottom: 10px;
    }
`

const Container = styled.div`
    margin-bottom: 25px;

    @media (min-width: 768px) {
        margin-bottom: 60px;
    }
`

const Text = styled.span`
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 1;

    @media (min-width: 768px) {
        font-size: 1.125rem;
    }
`

const DotContainer = styled.div`
    position: relative;
    margin-right: 10px;
`

const Dot = styled.div`
    border-radius: 50%;
    background: #2c6774;
    position: relative;
    width: 7px;
    height: 7px;
    z-index: 2;
`

const pulsate = keyframes`
    0% { transform: translate(-4px, -4px) scale(0.1, 0.1); opacity: 0; }
    50% { opacity: 1; }
    100% { transform: translate(-4px, -4px) scale(1.1, 1.1); opacity: 0;}
`

const Pulse = styled.div`
    background: rgba(147, 178, 184, 0.54);
    position: absolute;
    border-radius: 50%;
    transform: translate(-4px, -4px);
    top: 0;
    left: 0;
    z-index: 1;
    height: 15px;
    width: 15px;
    animation: ${pulsate} 1.8s ease-out infinite;
    opacity: 0;
`

const Description = styled.span`
    font-size: 14px;
    display: block;
    margin-top: 10px;

    @media (min-width: 768px) {
        font-size: 14px;
    }
`
