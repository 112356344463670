import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'
import { Heading } from '../components/heading'
import { Intro } from '../components/intro'
import { Signup } from '../components/signup/signup'
import { FAQ } from '../components/faq/faq'
import { Stats } from '../components/stats'
import { LiveButton } from '../components/live_button'
import { Features } from '../components/features'
import { ProofModal } from '../components/transactions/proof_modal'
import { ProofTable } from '../components/transactions/proof_table'
import { ProverAnimation } from '../components/prover_animation'
import LogoGroupImage from '../assets/logo-group.svg'
import mitt from 'mitt'

export const emitter = mitt()

class IndexPage extends React.Component {
    state = {
        isProofModalOpen: false,
        nextProof: null,
        nextProofCount: 1024,
        nextProofIndex: -1,
        proof: {
            id: null,
            txHash: null,
        },
        proofId: null,
        batchStats: {
            proofCount: 1,
            tradeCount: 0,
            settledTrades: 0,
            gasCost: 0,
            averageGasCost: 0,
            totalGasCost: 0,
            averageGasCostPerProof: 0,
            averageCostPerTrade: 0,
            txStateToCount: { FINALIZED: 0, PROOF_FAILED: 0, PROVED: 0 },
        },
        isLive: false,
        latestBatches: [],
        latestBatchesProved: [],
        isLoading: true,
    }
    constructor(props) {
        super(props)

        this._onCloseModal = this._onCloseModal.bind(this)
        this._open = this._open.bind(this)
        this.scheduler = null
        this.latestBatches = new Map()
        this.latestBatchStatuses = null
    }

    componentDidMount() {
        this.fetchData().then(response => {
            const {
                batchStats,
                notFinalizedBatches,
                provedBatches,
                finalizedBatches,
                isLive,
            } = response
            this.latestBatches = new Map(
                notFinalizedBatches.map(proof => [proof.id, proof])
            )

            emitter.emit('PROOFS_BEING_VERIFIED', { proofs: provedBatches })

            this.setState({
                batchStats: batchStats,
                latestBatchesProved: finalizedBatches,
                isLive,
                isLoading: false,
            })
            this.setupScheduler()
        })
    }

    setupScheduler() {
        this.dataScheduler = setInterval(() => {
            this.fetchData().then(response => {
                const {
                    sortedBatches,
                    finalizedBatches,
                    batchStats,
                    isLive,
                } = response

                sortedBatches.forEach(proof => {
                    const existingProof = this.latestBatches.get(proof.id)

                    if (
                        existingProof !== undefined &&
                        existingProof.state !== proof.state
                    ) {
                        emitter.emit('PROOF_CHANGED_STATE', {
                            proof,
                            oldState: existingProof.state,
                            newState: proof.state,
                        })
                    }

                    this.latestBatches.set(proof.id, proof)
                })
                this.setState({
                    batchStats,
                    isLive,
                })

                // How many is proving now
                // How many is proved now
                // How many is finalized
                //emitter.emit('FINALIZED', { proof:  })

                // eslint-disable-next-line no-console
                //console.log('Scheduler checking data', finalizedBatches, sortedBatches)

                // If proofs has become finalized and exists in queue
                // Remove it
                // Then queue animation
            })
            // Need to check
        }, 5000)
    }

    fetchData() {
        // return fetch('/api-generated.json', {
        return fetch('https://starkgateway.0x.org/v1', {
            method: 'GET',
        })
            .then(response => {
                return response.json()
            })
            .then(json => {
                const { latestBatches, isLive, ...batchStats } = json

                if (!latestBatches) {
                    return {}
                }

                const sortedBatches = latestBatches
                //.reverse()
                //.sort((a, b) => a.id - b.id)
                const finalizedBatches = sortedBatches.filter(
                    proof => proof.state === 'FINALIZED'
                )
                const notFinalizedBatches = sortedBatches.filter(
                    proof => proof.state !== 'FINALIZED'
                )
                const pendingBatches = sortedBatches.filter(
                    proof => proof.state === 'PENDING'
                )
                const provingBatches = sortedBatches.filter(
                    proof => proof.state === 'PROVING'
                )
                const provedBatches = sortedBatches.filter(
                    proof => proof.state === 'PROVED'
                )
                batchStats.latestCreatedTime =
                    batchStats.latestFinalizedBatchTime

                return {
                    batchStats,
                    isLive,
                    sortedBatches,
                    pendingBatches,
                    provingBatches,
                    notFinalizedBatches,
                    provedBatches,
                    finalizedBatches,
                }
            })
            .catch(console.info)
    }

    render() {
        const {
            isProofModalOpen,
            batchStats,
            isLoading,
            isLive,
            proof,
            proofId,
            latestBatches,
            nextProofCount,
            latestBatchesProved,
        } = this.state

        return (
            <Layout>
                <SEO
                    title="StarkDEX Alpha: Bringing STARKs to Ethereum"
                    keywords={[
                        'DEX',
                        'DEX scalability',
                        'non-custodial trading',
                        'Decentralized Exchange',
                        'Ethereum',
                        '0x',
                        '0x Protocol',
                        'StarkWare',
                        'StarkDEX',
                        'StarkDEX Alpha',
                        'Tokens',
                        'ZRX',
                        'Crypto Exchange',
                        'Cryptocurrency',
                        'Crypto',
                        'DeFi',
                        'Decentralized Finance',
                        'Open Finance',
                        'Exchange Protocol',
                        'Relayer',
                        'Exchange Functionality',
                        'Crypto Developers',
                        'Crypto Market Maker',
                        'Networked Liquidity',
                        'zero knowledge proofs',
                        'STARKs',
                        'Crypto Infrastructure',
                        'Build on 0x',
                        'crypto scalability',
                        'blockchain',
                        'blockchain scalability',
                    ]}
                />
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: '120px',
                    }}
                >
                    <Heading size="large">Bringing STARKs to Ethereum</Heading>
                    <Intro maxWidth="760px">
                        0x and StarkWare have developed a proof-of-concept
                        scalability engine that features simulated data sampled
                        from a live Binance feed.
                    </Intro>

                    <LogoGroup />
                </div>

                <Grid>
                    <Features />
                </Grid>

                <Grid>
                    <StatsColumn id="explorer">
                        <StatsHeader>
                            <StatsHeading>
                                STARK
                                <br />
                                <span>Scalability Engine</span>
                            </StatsHeading>
                            <LiveButton isLive={isLive} />
                        </StatsHeader>

                        <Stats stats={batchStats} />
                    </StatsColumn>
                    <IllustrationColumn>
                        <ProverAnimation
                            onAnimationEnd={this._onAnimationEnd.bind(this)}
                        />
                    </IllustrationColumn>
                </Grid>

                <Grid>
                    <ProofTable
                        isLoading={false}
                        proofs={latestBatchesProved}
                        onClick={this._open.bind(this)}
                    />
                </Grid>

                <FAQ />
                <Signup />

                <ProofModal
                    proofId={proofId}
                    proof={proof}
                    isOpen={isProofModalOpen}
                    onClose={this._onCloseModal}
                />
            </Layout>
        )
    }

    _open(proof) {
        this.setState({
            proof: proof,
            proofId: proof.id,
            isProofModalOpen: true,
        })
    }

    _onCloseModal() {
        this.setState({
            isProofModalOpen: false,
        })
    }

    _onAnimationEnd(proof) {
        const { latestBatchesProved } = this.state

        latestBatchesProved.unshift(proof)
        this.setState({
            latestBatchesProved,
        })
    }
}

const LogoGroup = styled(LogoGroupImage)`
    margin-top: 0px;
    margin-bottom: 40px;
    width: 105px;

    @media (min-width: 768px) {
        margin-top: 20px;
        margin-bottom: 80px;
        width: 143px;
    }
`

const Grid = styled.section`
    @media (min-width: 960px) {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-column-gap: 10px;
    }
`

const StatsColumn = styled.div`
    grid-column: 2 / 5;
    grid-row: 1;
    z-index: 2;
`

const StatsHeader = styled.div`
    text-align: center;

    @media (min-width: 960px) {
        text-align: left;
    }
`

const StatsHeading = styled(Heading)`
    font-size: 2.625rem;
    line-height: 1;

    @media (min-width: 768) {
        line-height: 0.9;
    }

    span {
        font-size: 1.75rem;
    }
`
const IllustrationColumn = styled.div`
    grid-column: 1 / 13;
    grid-row: 1;
    //padding-bottom: 170px;
    overflow: hidden;
`

export default IndexPage
