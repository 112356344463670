import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

const decimalFormatter = number => {
    return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits: 0,
    }).format(number)
}
const timeSinceRender = latestCreatedTime => {
    let timeSince = undefined
    if (latestCreatedTime) {
        const startDate = moment('2019-05-21 16:52:30.415')
        const endDate = moment(latestCreatedTime)
        const diffTime = moment(endDate).diff(startDate)
        const duration = moment.duration(diffTime)
        timeSince = `${duration.days()}d ${duration.hours()}h ${duration.minutes()}m`
    }
    return timeSince
}

export const Stats = ({ stats }) => {
    const settledTrades = decimalFormatter(stats.txStateToCount['FINALIZED'])
    const averageGasPerTrade = decimalFormatter(stats.averageCostPerTrade)
    const averageTradesPerProof = decimalFormatter(
        stats.tradeCount / stats.proofCount
    )
    const averageGasPerProof = decimalFormatter(stats.averageGasCostPerProof)
    let timeSince = timeSinceRender(stats.latestCreatedTime)

    return (
        <Container>
            <Heading>
                State
                <br /> Explorer
            </Heading>
            <Stat>
                <Number>
                    {averageGasPerTrade} <Suffix>gas</Suffix>
                </Number>
                <Description>average gas per trade</Description>
            </Stat>
            <Stat>
                <Number>{averageTradesPerProof}</Number>
                <Description>average trades per proof</Description>
            </Stat>
            <Stat>
                <Number>{settledTrades}</Number>
                <Description>
                    settled trades {timeSince ? `over ${timeSince}` : ''}
                </Description>
            </Stat>
            <Stat>
                <Number>
                    {averageGasPerProof} <Suffix>gas</Suffix>
                </Number>
                <Description>average gas per proof</Description>
            </Stat>
        </Container>
    )
}

Stats.defaultProps = {
    stats: {
        tradeCount: 0,
        proofCount: 1,
        settledTrades: 0,
        gasCost: 0,
        averageGasCost: 0,
        totalGasCost: 0,
        averageGasCostPerProof: 1000,
        averageCostPerTrade: 10000,
        txStateToCount: { FINALIZED: 0, PROOF_FAILED: 64, PROVED: 403 },
    },
}

const Container = styled.div`
    padding: 0 0;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (min-width: 768px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 20px;
        align-items: center;
        justify-items: center;
    }

    @media (min-width: 960px) {
        display: block;
        position: sticky;
        top: 20px;
        padding-bottom: 60px;
    }
`

const Heading = styled.h3`
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 1.2;
    grid-column: 1 / 3;

    @media (min-width: 768px) {
        font-size: 1.25rem;
    }

    @media (min-width: 768px) and (max-width: 960px) {
        line-height: 1.2;
        grid-column: 2 / 4;
        text-align: center;
    }

    @media (max-width: 768px) {
        br {
            display: none;
        }
    }
`

const Stat = styled.p`
    margin-bottom: 1.25rem;

    @media (min-width: 768px) {
        margin-bottom: 2rem;
        grid-row: 2;
    }

    @media (min-width: 768px) and (max-width: 960px) {
        align-self: start;
    }
`

const Number = styled.span`
    font-size: 1.5rem;
    font-weight: 700;
    display: block;
    margin-bottom: 5px;

    @media (min-width: 768px) {
        font-size: 2.5rem;
    }

    @media (min-width: 768px) and (max-width: 960px) {
        line-height: 0.7em;
        margin-bottom: 15px;
    }
`

const Description = styled.span`
    font-size: 0.625rem;
    display: block;

    @media (min-width: 768px) {
        font-size: 1rem;
    }
`

const Suffix = styled.span`
    font-size: 1.25rem;
`
