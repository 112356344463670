import React from 'react'
import styled from 'styled-components'

export const Intro = ({ children, asElement = 'p', maxWidth }) => (
    <Container maxWidth={maxWidth} as={asElement}>
        {children}
    </Container>
)

const Container = styled.p`
    max-width: ${props => props.maxWidth};
    font-size: 1rem;
    line-height: 1.4;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 768px) {
        font-size: 1.125rem;
        margin-top: 60px;
    }
`
