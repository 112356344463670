import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import ConfirmationIconImage from '../../assets/confirmation.svg'

export const Signup = () => {
    const [submitting, setSubmitting] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const inputEl = useRef(null)
    const onSubmit = e => {
        e.preventDefault()
        const value = inputEl.current.value
        setSubmitting(true)

        return fetch(
            `https://script.google.com/macros/s/AKfycbx8hBXiJgaKlQpzyB9PADvFjrfqNLGvdoCgDoDfKtlcozaAWjda/exec?Email=${value}`
        )
            .then(response => {
                return response.json()
            })
            .then(json => {
                setSubmitted(true)
            })
    }

    return (
        <Container onSubmit={onSubmit} id="newsletter">
            <Inner isSubmitted={submitted}>
                <Heading>Want to receive updates? Sign up below.</Heading>
                <Input type="email" ref={inputEl} required />
                <Button disabled={submitting}>Submit</Button>
            </Inner>
            <Success isSubmitted={submitted}>
                <ConfirmationIcon />
                <p>
                    <strong>Thank you!</strong> You were added to the list.
                </p>
            </Success>
        </Container>
    )
}

Signup.defaultProps = {}

const Container = styled.form`
    margin-top: 60px;
    background: #f4fcfa;
    border-radius: 20px;
    margin: 60px auto 60px;
    padding: 30px 20px;
    max-width: 600px;
    min-height: 280px;
    position: relative;

    @media (min-width: 768px) {
        padding: 40px 50px;
    }
`

const Inner = styled.div`
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;

    opacity: ${props => props.isSubmitted && 0};
    visibility: ${props => props.isSubmitted && 'hidden'};
`

const Success = styled.div`
    font-size: 1rem;
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
    opacity: ${props => (props.isSubmitted ? 1 : 0)};
    visibility: ${props => (props.isSubmitted ? 'visible' : 'hidden')};

    p {
        margin-bottom: 0;
    }
`

const ConfirmationIcon = styled(ConfirmationIconImage)`
    margin: 0 auto 20px;
`

const Heading = styled.h2`
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.2;
    max-width: 440px;
    margin-bottom: 20px;

    @media (min-width: 768px) {
        font-size: 1.25rem;
        margin-bottom: 40px;
    }
`

const Input = styled.input.attrs({
    type: 'email',
    placeholder: 'Email Address',
})`
    color: inherit;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1;
    width: 144px;
    background: transparent;
    border-radius: 20px;
    padding: 10px 10px;
    border: 0;
    border: 1px solid #2c6774;
    border-radius: 0;
    border-width: 0 0 1px 0;
    width: 335px;
    margin-bottom: 20px;
    text-align: center;
    max-width: 100%;

    @media (min-width: 768px) {
        font-size: 1.25rem;
        margin-bottom: 45px;
    }
`

const Button = styled.button.attrs({
    type: 'submit',
})`
    color: #fff;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1;
    width: 144px;
    background: #2c6774;
    border-radius: 20px;
    padding: 10px 10px;
    border: 0;

    &:disabled {
        opacity: 0.5;
    }

    @media (min-width: 768px) {
        font-size: 1.25rem;
    }
`
