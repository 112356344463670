import React from 'react'
import styled, { keyframes } from 'styled-components'


const Loading = () => (
    <Container>
        <Line />
        <Line />
        <Line />
    </Container>
)

export default Loading

const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const movementKeyframes = keyframes`
    0 {transform: translate(0,0);}
    50% {transform: translate(0,15px);}
    100% {transform: translate(0,0);}
`

const Line = styled.div`
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: #2C6774;

    & + & {
        margin-left: 3px;
    }

    &:nth-child(1) {
        animation: ${movementKeyframes} 0.6s 0.1s linear infinite;
    }

    &:nth-child(2) {
        animation: ${movementKeyframes} 0.6s 0.2s linear infinite;
    }

    &:nth-child(3) {
        animation: ${movementKeyframes} 0.6s 0.3s linear infinite;
    }
`
