import React from 'react'
import styled from 'styled-components'
import { Heading } from '../heading'
import { Category } from './category'

const categories = [
    {
        title: 'General',
        questions: [
            {
                question: 'What Is StarkDEX?',
                answer: (
                    <>
                        <p>
                            {`StarkDEX is a scalability engine - currently handling only settlements - for non-custodial
                            exchanges. It allows the lion’s share of the
                            exchange's workload to be done off-chain and provide
                            the blockchain an easily verifiable proof of the
                            integrity of the work performed. This is the Alpha
                            of StarkDEX for 0x Relayers, deployed on a public
                            Ethereum testnet.`}
                        </p>
                    </>
                ),
            },
            {
                question: `What Is STARK?`,
                answer: (
                    <>
                        <p>
                            STARK is a succinct proof protocol that is scalable
                            and transparent. Transparent (the T in STARK) means
                            it requires no trusted setup (unlike other proof
                            systems). Scalable (the S in STARK) means the prover
                            time scales quasi-linearly with respect to a native
                            computation, while verifier time scales
                            logarithmically.
                        </p>
                        <p>
                            This inherent asymmetry makes STARK an ideal
                            instrument for scaling computations on blockchains.
                            It allows a batch of transactions to be computed
                            off-chain and verified on-chain in an exponentially
                            smaller time, without adding any trust assumptions.
                            Batch size can grow with only a small increase in
                            cost, allowing scaling up to thousands, and
                            eventually tens of thousands of trades per second.
                        </p>
                    </>
                ),
            },
            {
                question: `How Does StarkDEX Enhance Decentralization?`,
                answer: (
                    <>
                        <p>
                            STARK enhances decentralization by supporting
                            Inclusive Accountability: the (computationally)
                            inexpensive verification on-chain of massive
                            computations done off-chain, allows even the
                            multitude of computationally-weak devices on the
                            blockchain to fully verify its integrity.
                        </p>
                        <p>
                            StarkDEX will support Inclusive Accountability by
                            dramatically reducing the computational cost of
                            verifying the integrity of huge crypto-assets
                            trading systems.
                        </p>
                    </>
                ),
            },
        ],
    },
    {
        title: 'Alpha',
        questions: [
            {
                question:
                    'System Overview - What Does the StarkDEX Alpha Include?',
                answer: (
                    <>
                        <p>
                            The StarkDEX Alpha includes on-chain and off-chain
                            components. On-chain, there is the verifier contract
                            and the deposit contract. Off-chain, there is the
                            StarkDEX service, the proof generation service, and
                            a data structure representing account balances.
                        </p>
                        <p>
                            The Alpha will include a simulation based on a
                            sampling of live Binance transactions (with up to
                            five minutes delay), which will be fed to the
                            system. A transaction can be a trade, a withdrawal,
                            or a deposit.
                        </p>
                    </>
                ),
            },
            {
                question: 'System Flow - How Does It Work?',
                answer: (
                    <>
                        <p>
                            StarkDEX utilizes STARKs to batch transactions into
                            a single proof that is verified on Ethereum. The
                            proof-of-concept utilizes both on-chain and
                            off-chain components to settle trades.
                        </p>
                        <ol>
                            <li>
                                The DEX (or in the Alpha, the simulator) sends a
                                batch of 0x orders to the StarkDEX Service, run
                                by StarkWare.
                            </li>
                            <li>
                                The StarkDEX Service checks the signatures of
                                each transaction in the batch, and the validity
                                of the transactions. The StarkDEX Service then
                                stores the data off-chain and adds it to the
                                execution trace it keeps.
                            </li>
                            <li>
                                The StarkDEX Service creates a proof of the
                                validity of the entire batch and sends it along
                                with the most recent state root to be verified
                                by the on-chain Verifier Contract, which
                                verifies the proof and sends the state root to
                                the DEX’s on-chain contract to be stored.
                            </li>
                            <li>
                                The Verifier Contract connects to the 0x smart
                                contract pipeline that moves the batch to be
                                confirmed on the Ethereum blockchain.
                            </li>
                        </ol>
                    </>
                ),
            },
            {
                question: 'What Is the Role of the Deposit Contract?',
                answer: (
                    <>
                        <p>
                            StarkDEX stores crypto-assets off-chain. When a user
                            wants to trade, they deposit the desired amount into
                            the deposit contract. The smart contract relies on
                            STARK proofs to transfer these assets off-chain, and
                            from then on to guarantee the integrity of the
                            off-chain state.
                        </p>
                        <p>
                            Users must <i>lock</i> assets on-chain in order to
                            ensure that assets are not spent in parallel both
                            on-chain and off-chain.
                        </p>
                    </>
                ),
            },
            {
                question: 'Who Are the Participating Developers?',
                answer: (
                    <>
                        <p>
                            The StarkDEX Alpha is developed by{' '}
                            <a
                                href="http://www.starkware.co/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                StarkWare
                            </a>{' '}
                            and{' '}
                            <a
                                href="https://0x.org/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                0x
                            </a>
                            .
                        </p>
                        <p>
                            0x is an open protocol that enables the peer-to-peer
                            exchange of assets on the Ethereum blockchain. 0x
                            provides exchange infrastructure for the entire
                            crypto economy and is working toward building a new
                            financial stack that is more efficient, transparent,
                            and equitable than any system in the past.
                        </p>
                        <p>
                            StarkWare solves the two most pressing problems of
                            permissionless blockchains: scalability and privacy.
                            StarkWare’s cryptographic proofs are zero-knowledge,
                            succinct, transparent and post-quantum secure.
                        </p>
                    </>
                ),
            },
            {
                question: 'How Is Data Availability Ensured?',
                answer: (
                    <>
                        <p>
                            Conceptually, one could store trade data either
                            on-chain or off-chain. The advantage of storing
                            trades on-chain is that data availability is as
                            resilient as the blockchain itself; the disadvantage
                            is that blockchain resources (gas, in the case of
                            Ethereum) are consumed linearly with the volume of
                            trading. We chose to store trade data (and account
                            balances) off-chain, to avoid this linear
                            consumption of blockchain resources. By doing so, we
                            allow StarkDEX to massively scale. This requires us
                            to guarantee data availability off-chain.
                        </p>
                        <p>
                            We will start out with data availability being
                            guaranteed by a trusted committee of participants
                            (example of potential participants: 0x, StarkWare,
                            DeFi participants, etc.). Down the road we plan to
                            replace it with a blockchain-based solution.
                        </p>
                    </>
                ),
            },
            {
                question: 'What Are the Software Licenses Used?',
                answer: (
                    <>
                        <p>
                            The verifier smart contract software will be
                            available under the Apache License, Version 2.0
                            available here:{' '}
                            <a
                                href="https://www.starkware.co/open-source-license/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                https://www.starkware.co/open-source-license/
                            </a>
                        </p>
                        <p>
                            The DEX smart contract software will be available
                            under the Apache License, Version 2.0 available
                            here:{' '}
                            <a href="/license">
                                https://www.starkdex.io/license/
                            </a>
                        </p>
                        <p>
                            The StarkDEX Service software is proprietary and
                            available only under a commercial license from
                            StarkWare Industries Ltd. Please contact
                            info@starkware.co to discuss license terms. Our
                            proprietary software is available for download only
                            with a license key.
                        </p>
                    </>
                ),
            },
        ],
    },
    {
        title: 'Roadmap',
        questions: [
            {
                question: 'When Will This Reach Mainnet?',
                answer: (
                    <>
                        <p>
                            We plan to launch a Minimally Viable Product (MVP)
                            on Mainnet which will support live users. This MVP
                            will include at least one DEX, and will allow
                            trading. Release date to be announced.
                        </p>
                    </>
                ),
            },
            {
                question: `What Is the Data Availability Committee's Long-Term Role?`,
                answer: (
                    <>
                        <p>
                            In the interest of enhancing decentralization, the
                            intent is to eventually decommission the data
                            availability committee, and replace it with a
                            blockchain-based solution.
                        </p>
                    </>
                ),
            },

            {
                question: 'What Is the Expected Latency?',
                answer: (
                    <>
                        <p>
                            Latency depends on whether a Relayer wishes to trust
                            the StarkDEX Service to indeed generate a proof in a
                            timely fashion for every batch of trades sent to it.
                        </p>
                        <p>
                            {`Relayers who make that assumption (or protect
                            themselves against its violation) will be able to
                            allow users to continue trading based on their
                            settlement history, as recorded in the Relayer’s
                            internal order book, even if proof for these
                            transactions has yet to be presented to the Verifier
                            Contract. In this case latency is actually better
                            than current blockchain transaction latency. The
                            only thing a user won't be able to do before a proof
                            is presented, is to withdraw funds.`}
                        </p>

                        <p>
                            A Relayer that wishes not to make that assumption
                            will not need to maintain an internal order book:
                            they will wait until a proof is presented before
                            allowing a user to continue trading on the basis of
                            a given trade. In this case latency will be a few
                            minutes starting from the initiation of a
                            settlement.
                        </p>
                    </>
                ),
            },
            {
                question: 'Is There an Escape Hatch?',
                answer: (
                    <>
                        <p>
                            The MVP will include an escape hatch. If any of the
                            entities participating in operating the MVP
                            (StarkWare, 0x, the 0x Relayer, the data
                            availability committee) become unresponsive, users
                            will know that they still retain self-custody of
                            their own crypto-assets.
                        </p>
                    </>
                ),
            },
        ],
    },
]

export class FAQ extends React.Component {
    state = {
        currentOpenIndex: '',
    }
    constructor(props) {
        super(props)
    }

    render() {
        const { currentOpenIndex } = this.state
        return (
            <Container id="faq">
                <Heading size="medium" mb="40px">
                    Frequently Asked Questions
                </Heading>
                {categories.map((category, index) => (
                    <Category
                        key={`category-${index}`}
                        title={category.title}
                        questions={category.questions}
                        id={`category-${index}`}
                        currentOpenIndex={currentOpenIndex}
                        onToggle={this.onToggle.bind(this)}
                    />
                ))}
            </Container>
        )
    }

    onToggle(id) {
        let { currentOpenIndex } = { ...this.state }

        if (id === currentOpenIndex) {
            currentOpenIndex = ''
        } else {
            currentOpenIndex = id
        }

        this.setState({
            currentOpenIndex,
        })
    }
}

const Container = styled.div`
    background: #f4fcfa;
    text-align: center;
    margin: 60px -20px 60px;
    padding: 45px 20px;
    max-width: 1160px;

    @media (min-width: 768px) {
        padding: 85px 50px;
        margin: 60px auto 60px;
        border-radius: 20px;
    }
`
