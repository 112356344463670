import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'

export const Features = () => (
    <Container>
        <FeatureHighlight
            maxWidth="250px"
            number="20"
            numberSuffix="x"
            copy="Up to 20x more trades per minute, compared to current DEX throughput"
        />
        <FeatureHighlight
            maxWidth="187px"
            number="95"
            numberSuffix="%"
            copy="Up to 95% improvement in gas efficiency"
        />
    </Container>
)

const FeatureHighlight = ({ maxWidth, number, numberSuffix, copy }) => (
    <FeatureContainer>
        <FeatureNumber>
            {number}
            <NumberSuffix>{numberSuffix}</NumberSuffix>
        </FeatureNumber>
        <FeatureCopy maxWidth={maxWidth}>{copy}</FeatureCopy>
    </FeatureContainer>
)

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    grid-column: 1 / 13;
    margin-bottom: 30px;
    grid-template-columns: 1fr 1fr;

    @media (min-width: 768px) {
        grid-column: 4 / 10;
        margin-bottom: 130px;
    }
`

const FeatureContainer = styled.div`
    text-align: center;
    margin: 0 20px;
`

const FeatureNumber = styled.h3`
    font-size: 2.117647059rem;
    font-weight: 700;
    margin-bottom: 0;

    @media (min-width: 768px) {
        font-size: 4.0625rem;
    }
`

const NumberSuffix = styled.span`
    font-size: 1.5rem;

    @media (min-width: 768px) {
        font-size: 2.5rem;
    }
`

const FeatureCopy = styled.p`
    font-size: 0.625rem;
    line-height: 1.2;
    max-width: ${props => props.maxWidth};

    @media (min-width: 768px) {
        font-size: 0.875rem;
    }
`
