import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'

import BetweenIconImage from '../../assets/transaction-separator-icon.svg'
import TransactionArrowIconImage from '../../assets/transaction-arrow.svg'

import Loading from '../loading'

const decimalFormatter = (number, maximumFractionDigits = 0) => {
    return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits,
    }).format(number)
}

const currencyFormatter = (number, maximumFractionDigits = 0) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits,
    }).format(number)
}

export class Transactions extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { onClick, isLoading, transactions } = this.props
        let { settlements } = transactions

        if (!settlements) {
            settlements = [];
        }

        return (
            <Container>
                <ListWrapper>
                    <List isLoading={isLoading}>
                        {settlements.map((transaction, index) => (
                            <Transaction
                                key={`transaction-${transaction.id}`}
                                {...transaction}
                            />
                        ))}
                    </List>
                </ListWrapper>
            </Container>
        )
    }
}

const Transaction = ({
    id,
    hash,
    makerAmount,
    takerAmount,
    makerAsset,
    takerAsset,
    price,
    url,
    onClick,
}) => {
    const formattedMakerAmount = decimalFormatter(makerAmount, 6)
    const formattedTakerAmount = decimalFormatter(takerAmount, 6)

    return (
        <TransactionContainer href={url} onClick={onClick}>
            <Detail>#{id}</Detail>
            <CurrencyDetail>
                <AssetDetail>
                    <span>{formattedMakerAmount}</span>
                    <span>{makerAsset}</span>
                </AssetDetail>
                <BetweenIcon />{' '}
                <AssetDetail>
                    <span>{formattedTakerAmount}</span>
                    <span>{takerAsset}</span>
                </AssetDetail>
            </CurrencyDetail>
        </TransactionContainer>
    )
}

Transaction.defaultProps = {
    onClick: () => {},
}

/**
 * Transaction list styles
 */
const Container = styled.div`
    grid-column: 1 / 13;

    @media (min-width: 768px) {
        grid-column: 4 / 10;
    }
`

const ListWrapper = styled.div`
    position: relative;
`

const List = styled.div`
    position: relative;
    margin-bottom: 0;
    padding: 15px 0;
    padding: 0;
    opacity: ${props => props.isLoading && 0};
`

const Copy = styled.p`
    font-size: 1rem;
    font-style: italic;
    max-width: 335px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 768px) {
        display: none;
    }
`

/**
 * Transaction styles
 */
const TransactionContainer = styled.div`
    min-height: 34px;
    background: #f4fcfa;
    color: #2c6774;
    border-radius: 20px;
    margin-bottom: 5px;
    //display: grid;
    //grid-template-columns: 1.5fr 1.5fr 1fr;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;
    transition: opacity 0.35s ease-in-out;

    &:first-child {
        /* Offset for gradient */
        /* margin-top: 15px;*/
    }
`

const Detail = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1rem;
    min-width: 80px;

    @media (max-width: 768px) {
        font-size: 0.75rem;
    }
`

const CurrencyDetail = styled(Detail)`
    margin-left: auto;
`

const AssetDetail = styled.span`
    display: inline-block;
    min-width: 160px;

    &:nth-child(1) {
        text-align: right;
    }

    > span {
        display: inline-block;
        //min-width: 70px;
    }

    > span {
        display: inline-block;

        &:nth-child(1) {
            min-width: 70px;
        }

        &:nth-child(2) {
            padding-left: 10px;
        }
    }
`

const DollarDetail = styled(Detail)`
    text-align: right;

    @media (max-width: 768px) {
        display: none;
    }
`

const TransactionArrowIcon = styled(TransactionArrowIconImage)`
    margin-left: 7px;

    @media (max-width: 768px) {
        margin-left: auto;
        width: 10px;
        height: 10px;
    }
`

const BetweenIcon = styled(BetweenIconImage)`
    margin-left: 15px;
    margin-right: 11px;
    width: 12px;
    height: 11px;
`
