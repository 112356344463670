import {
    TweenMax,
    TimelineMax,
    ModifiersPlugin,
    RoundPropsPlugin,
    AttrPlugin,
    CSSPlugin,
    Power2,
    Power4,
    Linear,
    TweenLite,
} from 'gsap/all'
import React from 'react'
import ProverImage from '../assets/prover8.svg'
import ProverLinesImage from '../assets/prover-lines.svg'
import styled from 'styled-components'

import { emitter } from '../pages/index'

const plugins = [CSSPlugin, AttrPlugin, ModifiersPlugin, RoundPropsPlugin]

function random(min, max) {
    if (max == null) {
        max = min
        min = 0
    }
    if (min > max) {
        var tmp = min
        min = max
        max = tmp
    }

    return min + (max - min) * Math.random()
}

const PROVER_CONTENT = [
    {
        id: 'prover#1',
        // eslint-disable-next-line prettier/prettier
        content:
            'The simulator replays a set of trades through our engine at a sustained rate of 10 trades per second. You can verify these trades yourself by looking into the latest proofs at the bottom of the page.',
        coords: { x: 360, y: 152 },
    },
    {
        id: 'prover#2',
        // eslint-disable-next-line prettier/prettier
        content: `Signed transactions are sent to StarkWare's StarkDEX Service to be included in a batch. The StarkDEX Service verifies the signatures, checks that the trades can be executed properly, and updates the token balances in the off-chain state. Once a batch is complete, the StarkDEX Service generates a proof attesting to the computational integrity of the entire batch and sends it along with the state root to the Verifier Contract.`,
        coords: { x: 360, y: 475 },
    },
    {
        id: 'prover#3',
        // eslint-disable-next-line prettier/prettier
        content: `The Verifier Contract receives a request to change the state - as represented by the new state root - along with the proof. The contract verifies the proof and accepts the state transition only after the proof was accepted. Since a proof for an invalid state transition cannot be generated, only valid state transitions are accepted.`,
        coords: { x: 360, y: 870 },
    },
    {
        id: 'prover#4',
        // eslint-disable-next-line prettier/prettier
        content:
            'The Verifier Contract connects with the 0x smart contract pipeline that funnels transactions to be settled on the Ethereum blockchain. On average it takes about 10 minutes for a trade to be settled on-chain.',
        coords: { x: 360, y: 1160 },
    },
    {
        id: 'prover#5',
        // eslint-disable-next-line prettier/prettier
        content:
            'To withdraw funds, the Verifier Contract verifies the proof containing the withdrawal, then updates the user token balance on-chain. If the StarkDEX Service does not fulfill the user request within a given timeframe, the user can withdraw their funds directly from the 0x smart contract.',
        coords: { x: 360, y: 1427 },
    },
]

TweenMax.defaultEase = Power4.easeInOut

export class ProverAnimation extends React.Component {
    state = {}
    constructor(props) {
        super(props)
        this.proofsBeingVerified = new Map()
        this.imageRef = React.createRef()
        this._onNextProof = this._onNextProof.bind(this)
    }

    componentDidMount() {
        const { current } = this.imageRef
        this.svg = current.querySelector('svg')
        this.timelineProgress = current.querySelector('.progress span')
        this.progressBar = this.svg.querySelector('#progressBar')
        this.progressBarRect = this.svg.querySelector('#progressBar rect')
        this.spinningLogo = this.svg.querySelector('#_x30_xLogo')
        this.balls = this.svg.querySelectorAll('#balls .st1')

        // Upper doors
        this.upperDoorLeft = this.svg.querySelector('#doorsUpperLeft path')
        this.upperDoorRight = this.svg.querySelector('#doorsUpperRight path')

        // Lower doors
        this.lowerDoorLeft = this.svg.querySelector('#doorsUpper_2_ path')
        this.lowerDoorRight = this.svg.querySelector('#doorsUpper_3_ path')

        // Verifier doors
        this.verifierDoorLeft = this.svg.querySelector('#doorsUpper_5_ path')
        this.verifierDoorRight = this.svg.querySelector('#doorsUpper_4_ path')

        // Chamber
        this.proof = this.svg.querySelector('#proof')
        this.proofMoveable = this.svg.querySelector('#proof2')
        this.proofMoveable2 = this.svg.querySelector('#proof3')
        this.pile = this.svg.querySelector('#pile')
        this.presser = this.svg.querySelector('#presserWhite')
        this.ethereumLogo = this.svg.querySelector('#ethereum')
        this.txnTxt = this.svg.querySelector('#txnTxt')
        this.verifierCheck = this.svg.querySelector('#verifierCheck')

        // Counter
        this.nextProofCount = 1024
        this.nextProofIndex = 1

        // eslint-disable-next-line no-debugger
        // debugger;

        this.setupTimeline()

        emitter.on('PROOF_CHANGED_STATE', this._onProofChangedState.bind(this))
        emitter.on(
            'PROOFS_BEING_VERIFIED',
            this._onProofsBeingVerifiedInit.bind(this)
        )
    }

    componentDidUpdate() {
        //this.setupTimeline()
    }

    shouldComponentUpdate(nextProps, nextState) {
        return false
    }

    setupTimeline() {
        const txns = { val: 0 }
        const newCount = 500

        /*
        if (this.mainTimeline !== undefined) {
            this.mainTimeline.sl().time(0)
        }

        if (this.pileTimeline !== undefined) {
            this.pileTimeline.stop().time(0)
        }

        if (this.ballTimeline !== undefined) {
            this.ballTimeline.stop().time(0)
        }

        if (this.proofTimeline !== undefined) {
            this.proofTimeline.stop().time(0)
        }*/

        this.ballTimeline = new TimelineMax({
            delay: 0,
            repeat: -1,
            paused: false,
        })
        this.mainTimeline = new TimelineMax({
            delay: 0,
            //repeat: -1,
            //paused: true,
        })
        this.pileTimeline = new TimelineMax({
            delay: 0,
            repeat: -1,
            paused: false,
        })
        this.proofTimeline = new TimelineMax({
            delay: 0,
            repeat: -1,
        })

        this.verifierTimeline = new TimelineMax({
            repeat: -1,
            paused: true,
        })
            .to(
                this.spinningLogo,
                2,
                {
                    rotation: 360,
                    ease: Linear.easeInOut,
                    transformOrigin: 'center center',
                    clearProps: 'all',
                },
                0
            )
            .to(
                this.ethereumLogo,
                1,
                {
                    y: '+=10px',
                    ease: Linear.easeInOut,
                    repeat: 1,
                    yoyo: true,
                },
                0
            )

        this.mainTimeline.eventCallback('onUpdate', () => {
            /*this.timelineProgress.innerText = Math.ceil(
                this.mainTimeline.progress() * 100
            )
            */
        })

        const placeBall = ball => {
            TweenMax.set(ball, {
                x: random(0, 170),
                y: random(10, 300),
            })
        }

        Array.from(this.balls).forEach(ball => {
            placeBall(ball)
        })

        Array.from(this.balls).forEach(ball => {
            this.ballTimeline.to(
                ball,
                1.5,
                {
                    ease: Linear.easeNone,
                    repeat: -1,
                    css: {
                        y: '+=400px !important', //move each box 500px down
                    },
                    modifiers: {
                        y: function(y) {
                            return y % 400 //force x value to be between 0 and 500 using modulus
                        },
                    },
                    onComplete: placeBall,
                    onCompleteParams: [ball],
                    //delay: random(1, 3),
                },
                '0'
            )
        })

        const txnTxt = this.txnTxt

        /*
        TweenMax.set(this.upperDoorLeft, {
            rotation: -120,
            transformOrigin: 'right top',
        })
        TweenMax.set(this.upperDoorRight, {
            rotation: 120,
            transformOrigin: 'left top',
        })

        TweenMax.set(this.lowerDoorLeft, {
            rotation: 45,
            transformOrigin: 'right bottom',
        })
        TweenMax.set(this.lowerDoorRight, {
            rotation: -120,
            transformOrigin: 'left bottom',
        })*/

        TweenMax.set(this.pile, {
            y: '+=14%',
        })

        let lastMoveableProofIndex = 0
        const moveableProofs = [this.proofMoveable, this.proofMoveable2]
        const getProofTimeline = index => {
            const moveableProof = moveableProofs[index]

            return (
                new TimelineMax({ repeat: false, paused: true })
                    .set(moveableProof, {
                        autoAlpha: 1,
                        scale: 0,
                        y: '0%',
                        transformOrigin: 'center center',
                    })
                    .add('chamberAnimation')
                    .to(
                        moveableProof,
                        3,
                        {
                            scale: 1,
                            transformOrigin: 'center center',
                            ease: Linear.easeInOut,
                            //repeat: -1,
                        },
                        'chamberAnimation'
                    )
                    .to(this.progressBar, 2, {
                        ease: Linear.easeInOut,
                        css: { width: 197 },
                        //repeat: -1,
                    })
                    .add('proofInChamber')
                    .to(
                        this.progressBar,
                        0.5,
                        {
                            css: { width: 0 },
                            ease: Linear.easeInOut,
                        },
                        'proofInChamber'
                    )
                    .to(
                        moveableProof,
                        3,
                        {
                            y: '+=1100%',
                            ease: Linear.easeInOut,
                            //repeat: -1,
                        },
                        'proofInChamber+=0.2'
                    )
                    // Need to this together with the proof
                    // Opening doors
                    /*
                    .to(
                        this.upperDoorLeft,
                        0.7,
                        {
                            rotation: 120,
                            transformOrigin: '2.5% 5px',
                            ease: Linear.easeInOut,
                        },
                        'proofInChamber-=0'
                    )
                    .to(
                        this.upperDoorRight,
                        0.7,
                        {
                            rotation: -120,
                            transformOrigin: '97.5% 5px',
                            ease: Linear.easeInOut,
                        },
                        'proofInChamber-=0'
                    )*/

                    // Closing doors
                    /*
                    .to(
                        this.upperDoorLeft,
                        0.5,
                        {
                            transformOrigin: '2.5% 5px',
                            rotation: 0,
                            ease: Linear.easeInOut,
                        },
                        '-=1.5'
                    )
                    .to(
                        this.upperDoorRight,
                        0.5,
                        {
                            transformOrigin: '97.5% 5px',
                            rotation: 0,
                            ease: Linear.easeInOut,
                        },
                        '-=1.5'
                    )*/
                    .add('endAnimation')
                    .add('closingDoors', '-=0.8')
                    // Opening doors
                    /*
                    .to(
                        this.lowerDoorLeft,
                        0.7,
                        {
                            transformOrigin: '2.5% 5px',
                            ease: Linear.easeInOut,
                            rotation: -120,
                            immediateRender: false,
                        },
                        'closingDoors'
                    )
                    .to(
                        this.lowerDoorRight,
                        0.7,
                        {
                            rotation: 120,
                            transformOrigin: '97.5% 5px',
                            ease: Linear.easeInOut,
                            immediateRender: false,
                        },
                        'closingDoors'
                    )*/
                    // Closing doors
                    /*
                    .to(
                        this.lowerDoorLeft,
                        0.7,
                        {
                            transformOrigin: '2.5% 5px',
                            ease: Linear.easeInOut,
                            rotation: 0,
                            immediateRender: false,
                            //repeat: -1,
                        },
                        'closingDoors'
                    )
                    .to(
                        this.lowerDoorRight,
                        0.7,
                        {
                            transformOrigin: '97.5% 5px',
                            ease: Linear.easeInOut,
                            rotation: 0,
                            immediateRender: false,
                            //repeat: -1,
                        },
                        'closingDoors'
                    )*/

                    // Verification animation
                    .to(
                        this.spinningLogo,
                        2,
                        {
                            rotation: 360,
                            ease: Linear.easeInOut,
                            repeat: 2,
                            transformOrigin: 'center center',
                            clearProps: 'all',
                        },
                        'endAnimation'
                    )
                    .to(
                        this.ethereumLogo,
                        1,
                        {
                            y: '+=10px',
                            ease: Linear.easeInOut,
                            repeat: 3,
                            yoyo: true,
                        },
                        'endAnimation'
                    )
                    .to(this.verifierCheck, 0.25, {
                        opacity: 1,
                        ease: Linear.easeInOut,
                    })
                    .add('moveProof')
                    .to(
                        moveableProof,
                        3,
                        {
                            y: '+=1500%',
                            ease: Linear.easeInOut,
                            //repeat: -1,
                        },
                        'moveProof'
                    )
                    .to(
                        this.verifierCheck,
                        0.5,
                        {
                            opacity: 0,
                            ease: Linear.easeInOut,
                        },
                        'moveProof+=1'
                    )
                    .add('verifierDoors')
                    // Verifier doors
                    // Opening doors
                    /*
                    .to(
                        this.verifierDoorLeft,
                        0.7,
                        {
                            rotation: 120,
                            transformOrigin: '2.5% 5px',
                            ease: Linear.easeInOut,
                            immediateRender: false,
                        },
                        'verifierDoors-=2.5'
                    )
                    .to(
                        this.verifierDoorRight,
                        0.7,
                        {
                            rotation: -120,
                            transformOrigin: '97.5% 5px',
                            ease: Linear.easeInOut,
                            immediateRender: false,
                        },
                        'verifierDoors-=2.5'
                    )*/

                    // Closing doors
                    /*
                    .to(
                        this.verifierDoorLeft,
                        0.5,
                        {
                            transformOrigin: '2.5% 5px',
                            rotation: 20,
                            ease: Linear.easeInOut,
                            immediateRender: false,
                        },
                        'verifierDoors-=0.5'
                    )
                    .to(
                        this.verifierDoorRight,
                        0.5,
                        {
                            transformOrigin: '97.5% 5px',
                            rotation: 60,
                            ease: Linear.easeInOut,
                            immediateRender: false,
                        },
                        'verifierDoors-=0.5'
                    )*/
                    // Adding row to proof table
                    // addPause instead of .call() prevents its from running both on play and pause
                    .add('fireCallback', '-=0.2')
                    .addPause('fireCallback', this.props.onAnimationEnd)
            )
        }

        let calledCountFirstTime = false
        const onCompletePile = () => {
            let newMoveableIndex = lastMoveableProofIndex === 1 ? 0 : 1

            lastMoveableProofIndex = newMoveableIndex

            return newMoveableIndex
        }
        const ballTimelines = [getProofTimeline(0), getProofTimeline(1)]
        const transactionsPerSec = 10
        const pendingTime = this.nextProofCount / transactionsPerSec
        const animateCount = () => {
            TweenMax.fromTo(
                txns,
                pendingTime,
                { val: 0 },
                {
                    val: this.nextProofCount,
                    ease: Linear.easeNone,
                    roundProps: 'val',
                    onUpdate: function() {
                        txnTxt.innerHTML = Math.round(txns.val)
                    },
                }
            )
        }

        this.pileTimeline
            .call(this._onNextProof)
            .add('startOfPile')
            .call(
                () => {
                    animateCount()
                },
                null,
                null,
                'startOfPile'
            )
            .fromTo(
                this.pile,
                pendingTime,
                {
                    y: '+=50',
                    ease: Linear.easeInOut,
                },
                {
                    y: '-=170',
                },
                'startOfPile'
            )
            .add('chamberAnimation')
            .to(
                this.pile,
                3,
                {
                    y: '+=125',
                    ease: Linear.easeInOut,
                },
                'chamberAnimation'
            )
            .to(
                this.presser,
                3,
                {
                    ease: Linear.easeInOut,
                    y: '+=125',
                },
                'chamberAnimation'
            )
            //.call(animateCount, null, null, 'chamberAnimation')
            .set(this.pile, { y: '+=25' })
            .add('proofInChamber')
            /*
            .set(
                txns,
                {
                    val: 0,
                    roundProps: 'val',
                    onUpdate: function() {
                        txnTxt.innerHTML = 0
                    },
                },
                'proofInChamber'
            )*/
            .to(
                this.presser,
                1,
                {
                    y: '-=120',
                    ease: Linear.easeInOut,
                },
                'proofInChamber'
            )

        TweenMax.to(this.proofMoveable2, 3, {
            autoAlpha: 1,
            yoyo: true,
            repeat: -1,
            ease: Power2.easeOut,
        })
        /*
            Trigger from queue instead
            .call(
                () => {
                    const index = onCompletePile()
                    const ballTimeline = ballTimelines[index]
                    ballTimeline.time(0).play()
                },
                null,
                null,
                'chamberAnimation'
            )*/
    }

    changeTime(value) {
        this.mainTimeline.progress(value).pause()
    }

    render() {
        return (
            <Container ref={this.imageRef}>
                <Prover />
                <ProverLines />
                <ProverTexts>
                    {PROVER_CONTENT.map(o => (
                        <ForeignObject key={o.id} x={o.coords.x} y={o.coords.y}>
                            <Text>{o.content}</Text>
                        </ForeignObject>
                    ))}
                </ProverTexts>
            </Container>
        )
    }

    _onProofsBeingVerifiedInit(event) {
        const { proofs } = event

        proofs.forEach(proof => {
            this.proofsBeingVerified.set(proof.id, true)
        })

        this._toggleVerifierAnimation()
    }

    _onProofChangedState(event) {
        const { proof, oldState, newState } = event

        if (newState === 'FINALIZED') {
            this._onFinalizedProof(proof)
        }

        if (newState === 'PROVED') {
            this._onProvedProof(proof)
        }
    }

    _onProvedProof(proof) {
        // Generate proof ball in lower place
        const verifiedProof = this.proofMoveable.cloneNode(true)
        verifiedProof.id = 'proof' + Math.ceil(Math.random() * 1000)
        this.proofMoveable.parentNode.insertBefore(
            verifiedProof,
            this.proofMoveable
        )

        const timeline = new TimelineMax()
        timeline
            .set(verifiedProof, {
                opacity: 1,
            })
            .add('moveProof')
            .fromTo(
                verifiedProof,
                2,
                {
                    y: 0,
                },
                {
                    y: 900,
                    ease: Linear.easeInOut,
                    onComplete: () => {
                        verifiedProof.parentNode.removeChild(verifiedProof)
                    },
                },
                'moveProof'
            )
            .add('fireCallback', '-=0.2')
            .addPause('fireCallback', () => {
                this.proofsBeingVerified.set(proof.id, true)
                this._toggleVerifierAnimation()
            })

        // Animate
    }

    _onFinalizedProof(proof) {
        // Generate proof ball at end of verifier
        const verifiedProof = this.proofMoveable.cloneNode(true)
        verifiedProof.id = 'proof' + Math.ceil(Math.random() * 1000)
        this.proofMoveable.parentNode.insertBefore(
            verifiedProof,
            this.proofMoveable
        )

        const timeline = new TimelineMax()
        timeline
            .set(verifiedProof, {
                y: 750,
                opacity: 1,
            })
            .to(this.verifierCheck, 1, {
                opacity: 1,
                ease: Linear.easeInOut,
            })
            .add('moveProof')
            .call(
                () => {
                    this.proofsBeingVerified.delete(proof.id)
                    this._toggleVerifierAnimation()
                },
                null,
                null,
                'moveProof'
            )
            .fromTo(
                verifiedProof,
                2,
                {
                    y: 750,
                },
                {
                    y: 1650,
                    ease: Linear.easeInOut,
                    onComplete: () => {
                        verifiedProof.parentNode.removeChild(verifiedProof)
                    },
                },
                'moveProof'
            )
            .to(
                this.verifierCheck,
                0.5,
                {
                    opacity: 0,
                    ease: Linear.easeInOut,
                },
                'moveProof'
            )
            .add('fireCallback', '-=0.2')
            .addPause('fireCallback', () => {
                this.props.onAnimationEnd(proof)
            })

        // Animate
    }

    _toggleVerifierAnimation() {
        // If there is proofs being verified and the verifier is paused, play it
        if (
            this.proofsBeingVerified.size > 0 &&
            this.verifierTimeline.paused()
        ) {
            this.verifierTimeline.time(0).play()
        }

        // If there is no more proofs being verified and the verifier is playing, pause it
        if (
            this.proofsBeingVerified.size === 0 &&
            !this.verifierTimeline.paused()
        ) {
            this.verifierTimeline.pause(this.verifierTimeline.duration())
        }
    }

    _onNextProof() {
        let { nextProofCount, nextProofIndex } = this

        nextProofCount = 1024
        nextProofIndex = nextProofIndex === 0 ? 1 : 0

        this.nextProofCount = nextProofCount
        this.nextProofIndex = nextProofIndex
    }
}

ProverAnimation.defaultProps = {
    onAnimationEnd: () => {},
}

const Container = styled.div`
    margin: 0 -20px 0 -20px;
    max-width: 235px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    @media (min-width: 768px) and (max-width: 960px) {
        margin-left: 8rem;
    }
`

const SliderContainer = styled.div`
    position: fixed;
    width: 400px;
    right: 20px;
    top: 20px;
    z-index: 9999;
    padding: 20px;
`

const Progress = styled.div`
    position: fixed;
    width: 50px;
    right: 420px;
    top: 20px;
    z-index: 99990;
    padding-top: 15px;
    font-size: 1rem;
`

const Prover = styled(ProverImage)`
    width: 100%;
    height: auto;

    #proof,
    #proof2,
    #proof3,
    #verifierCheck {
        opacity: 0;
    }
`

const ProverLines = styled(ProverLinesImage)`
    position: absolute;
    width: 545px;
    top: 0;
    left: 0;
    z-index: 1;
    transform: translate(-156px, -175px);
    display: none;

    @media (min-width: 768px) {
        display: block;
    }
`

const ProverTexts = styled.div`
    position: absolute;
    display: none;
    width: 946px;
    height: 112%;
    top: 0;
    left: 0;
    z-index: 1;

    @media (min-width: 768px) {
        display: block;
    }
`

const ForeignObject = styled.div`
    width: 18vw;
    height: 302px;
    position: absolute;
    top: ${props => props.y}px;
    left: ${props => props.x}px;

    @media (min-width: 768px) {
        width: 20vw;
    }

    @media (min-width: 768px) and (max-width: 960px) {
        width: 25vw;
    }

    @media (min-width: 1300px) {
        width: 360px;
    }
`

const Text = styled.p`
    font-size: 0.9rem;
    line-height: 1.4;
    text-indent: 20px;

    @media (min-width: 768px) and (max-width: 960px) {
        font-size: 0.9rem;
    }

    @media (min-width: 960px) {
        font-size: 1rem;
    }
`
