import React from 'react'
import styled from 'styled-components'

const decimalFormatter = (number, decimals = 0) => {
    return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits: decimals,
    }).format(number)
}

export const StatsModal = ({ stats }) => {
    const { gas, txCount } = stats
    const gasPerTrade = decimalFormatter(gas / txCount, 0)
    const totalGas = decimalFormatter(gas, 0)

    return (
        <Container>
            <Stat>
                <Number>{decimalFormatter(txCount)}</Number>
                <Description>trades</Description>
            </Stat>
            <Stat>
                <Number>{gasPerTrade}</Number>
                <Description>gas per trade</Description>
            </Stat>
            <Stat>
                <Number>{totalGas}</Number>
                <Description>total gas cost</Description>
            </Stat>
        </Container>
    )
}

StatsModal.defaultProps = {
    stats: {
        txCount: 0,
        totalTrades: '512',
        gasCost: '$10.38',
        gas: 0,
        totalGasCost: '$10.38',
        averageGasCost: '.003',
    },
}

const Container = styled.div`
    padding: 0 0;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @media (min-width: 768px) {
        display: block;
    }
`

const Heading = styled.h3`
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 1.2;
    grid-column: 1 / 3;

    @media (min-width: 768px) {
        font-size: 1.25rem;
    }

    @media (max-width: 768px) {
        br {
            display: none;
        }
    }
`

const Stat = styled.p`
    margin-bottom: 1.25rem;

    @media (min-width: 768px) {
        margin-bottom: 2rem;
    }
`

const Number = styled.span`
    font-size: 1.5rem;
    font-weight: 700;
    display: block;
    margin-bottom: 5px;

    @media (min-width: 768px) {
        font-size: 2.5rem;
    }
`

const Description = styled.span`
    font-size: 0.625rem;
    display: block;

    @media (min-width: 768px) {
        font-size: 1rem;
    }
`

const Suffix = styled.span`
    font-size: 1.25rem;
`
